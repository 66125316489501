.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.0);
    z-index: 1000;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-left {
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.header-right {
    display: flex;
    align-items: center;
    margin-left: auto; /* 버튼들을 오른쪽으로 밀기 위해 설정 */
    gap: 30px; /* 버튼 간의 간격 설정 */
    padding-right: 30px; /* 오른쪽 여백 설정으로 버튼이 잘리지 않도록 조정 */
    
}

.logo {
    height: 25px;
}

/* 배우 등록 버튼 스타일 */
.register-button {
    padding: 8px 16px;
    background-color: rgba(171, 100, 234, 0.3);
    color: #ffffff;
    border: 1px solid #AB64EA;
    border-radius: 8px;
    cursor: pointer;
    backdrop-filter: blur(6px);
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.register-button:hover {
    background-color: rgba(171, 100, 234, 0.6);
    border-color: #ffffff;
}


.header-button {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
}
.header-button-ai{
    background: linear-gradient(to right top, #fa709a, #fee140);
    color: transparent;
    -webkit-background-clip: text;
    font-weight: 700;
    font-size: 16px;
    border: 0px;
}
.menu-link{
    display: none;
}

.header-button-menu:hover,
.header-button-ai:hover,
.header-button:hover {
    opacity: 70%;
}
.header-button-menu{
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 700;
    display: none;
    justify-content: center;
    align-items: center;
}

.header-button-img{
    width: 25px;
    height: 25px;
}

@media (max-width: 768px) {
    .header {
        flex-direction: row;
        justify-content: space-between;
    }
    .register-button,
    .header-button-ai,
    .header-button{
        display: none;
    }
    .header-left{
        padding-left: 16px;
    }
    .header-right{
        padding-right: 16px;
    }
    .menu-link,
    .header-button-menu{
        display: flex;
    }
}


