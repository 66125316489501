body {
  background-color: #15171e;
  color: #ffffff;
  font-family: 'Noto Sans KR', sans-serif;
  margin: 0;
  padding: 0;
  position: relative; /* body에 상대 위치를 설정 */
}

body::before {
  content: "";
  position: fixed; /* 고정된 배경 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/img/bgimg.jpg'); /* 실제 이미지 경로로 대체 */
  background-size: cover; /* 배경 이미지를 화면에 맞게 채우기 */
  background-position: center; /* 배경 이미지 중앙 정렬 */
  background-repeat: no-repeat; /* 배경 이미지 반복 방지 */
  z-index: -1; /* 모든 요소 뒤에 위치 */
  backdrop-filter: blur(8px); /* 흐림 효과 추가 */
}

.search-bar {
  display: flex;
  flex-direction: column; /* 세로로 정렬 */
  align-items: center;
  margin-top: 40px; /* 위쪽 여백 추가 */
  padding: 20px;
  text-align: center; /* 텍스트 가운데 정렬 */
}
 
  .search-title {
    margin-bottom: 30px; /* 타이틀 아래 간격 */
    font-size: 36px; /* 글자 크기 키움 */
    color: #ffffff; /* 흰색 글씨 */
  }
  .synopsis-input,
  .character-input
  {
    width: 100%; /* 가로 폭 동일하게 설정 */
    padding: 10px 0px 10px 0px;
    font-size: 16px;
    margin-bottom: 30px; /* 입력창 아래 간격을 더 넓게 설정하여 글자 수 표시와 간격 확보 */
    border: 1px solid rgba(255, 255, 255, 0.2); /* 흐림 효과를 위한 테두리 */
    border-radius: 10px;
    background-color: rgba(28, 30, 38, 0.5); /* 배경 흐림 효과를 위한 투명도 추가 */
    backdrop-filter: blur(8px); /* 배경 흐림 효과 추가 */
    color: #ffffff;
    position: relative; /* 글자 수 표시를 위한 상대적 위치 설정 */
    padding: 10px;
    box-sizing: border-box;
  }
  
.input-all-container{
  width: 800px;
}

  .input-container {
    position: relative; /* 상대적 위치 설정 */
    width: 100px; /* 입력창과 동일한 너비 설정 */
    margin-bottom: 20px; /* 입력창 아래 간격 */
  }
  
  .char-count {
    position: absolute; /* 절대적 위치 설정 */
    bottom: 5px; /* 입력창 하단에서 5px 위로 위치 */
    right: 10px; /* 입력창 오른쪽에서 10px 왼쪽으로 위치 */
    font-size: 12px; /* 글자 크기 설정 */
    color: #ffffff; /* 글자 색상 설정 */
    opacity: 0.7; /* 약간의 투명도 설정 */
  }
  
  .synopsis-input {
    height: 200px; /* 시놉시스 입력창의 세로 폭 설정 */
    
  }

  .character-input{
    height: 50px;
  }
 
  .detail-search-button {
    margin-top: 10px; /* 버튼 위 간격 */
    width: 50%;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 10px;
    background-image: linear-gradient(to right, #8a2387, #e94057, #f27121);
    backdrop-filter: blur(6px); /* 배경 흐림 효과 추가 */
    /* border: 2px solid #ffffff; */
    transition: background-color 0.3s ease;
  }
 
  .detail-search-button:hover {
    background-color: rgba(171, 100, 234, 0.6); /* 호버 시 배경색 진하게 */
    background-image: linear-gradient(to right, #009fff, #ec2f4b);
    border-color: #333333; /* 호버 시 테두리 색 변경 */
  }


  .change-button {
    margin-top: 10px; /* 버튼 위 간격 */
    width: 50%;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 5px;
    background-color: rgba(131, 131, 131, 0); 
    backdrop-filter: blur(6px); /* 배경 흐림 효과 추가 */
    border: 1px solid #c5c5c5; /* 보라색 테두리 */
    transition: background-color 0.3s ease;
  }
  
  .change-button:hover {
    background-color: rgba(0, 0, 0, 0.3); /* 호버 시 배경색 진하게 */
    border-color: #ffffff; /* 호버 시 테두리 색 변경 */
  }


  
  .actor-card-slider {
    width: 100%;
    overflow-x: auto; /* 가로 스크롤 활성화 */
    display: flex;
    margin-top: 20px; /* 슬라이더 위 간격 */
    gap: 10px; /* 카드 간의 간격 */
    scroll-behavior: smooth; /* 부드러운 스크롤 */
  }
  
  .filter-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px; /* 두 개의 div 사이 간격 */
    flex-wrap: wrap;
  }
  .filter-section-mobile,
  .filter-section,
  .keyword-section {
    width: 300px; /* 두 개의 div 너비 동일하게 설정 */
    height: 300px; /* 두 개의 div 높이 동일하게 설정 */
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.2); /* 테두리 흐리게 설정 */
    background-color: rgba(0, 0, 0, 0.3); /* 배경색 0.3 불투명도 검정색 */
    backdrop-filter: blur(8px); /* 배경 흐림 효과 추가 */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* gap: 15px; 필터 그룹 사이 간격 */
    }
.filter-input-group{
  display: flex;
  align-items: center;
  gap: 5px;
}
  
  .filter-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: row;
  }
  
  .filter-group label {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 5px;
  }
  
  .filter-group div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .filter-radio-group{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* 숨겨진 기본 라디오 버튼 */
  .filter-radio-group input[type="radio"] {
    display: none;
  }
  
  /* 커스텀 라디오 버튼 */
  .filter-radio-group label {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    background-color: #1c1e26;
    border: 1px solid #2c2c34;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  /* 라디오 버튼 선택 상태 */
  .filter-radio-group input[type="radio"]:checked + label {
    background-color: #1f2b39;
    border-color: #3d5afe;
    color: #ffffff;
    position: relative;
  }
  
  .filter-radio-group input[type="radio"]:checked + label::before {
  
    position: absolute;
    left: 10px;
    font-size: 14px;
    color: #3d5afe;
  }

  .filter-num-input {
    width: 70px; /* 입력 필드가 부모 요소의 너비를 채우도록 설정 */
    padding: 10px;
    margin: 5px 0; /* 입력 필드 상하 여백 설정 */
    border: 1px solid #2c2c34;
    border-radius: 8px;
    background-color: #1c1e26;
    color: #ffffff;
    font-size: 14px;
    box-sizing: border-box; /* 입력 필드 크기 조정이 좌우 여백을 침범하지 않도록 설정 */
  }

  .filter-fee-num-input {
    width: 150px; /* 입력 필드가 부모 요소의 너비를 채우도록 설정 */
    padding: 10px;
    margin: 5px 0; /* 입력 필드 상하 여백 설정 */
    border: 1px solid #2c2c34;
    border-radius: 8px;
    background-color: #1c1e26;
    color: #ffffff;
    font-size: 14px;
    box-sizing: border-box; /* 입력 필드 크기 조정이 좌우 여백을 침범하지 않도록 설정 */
  }

  .filter-form-label{
    width: 70px;
    color:#ffffff;
    text-align: left;
  }

  .keyword-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-color: rgba(28, 30, 38, 0.5);
    color: #ffffff;
    box-sizing: border-box;
  }


  .keyword-container {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .keyword-list {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .keyword-item {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .keyword-item button {
    margin-left: 5px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
  }  


  /* 검색창 공통 스타일 */
 .input-range {
    width: 100%;
    max-width: 500px; /* 최대 너비를 설정하여 중앙에 배치 */
    margin-bottom: 20px; /* 아래 간격 */
    position: relative;
  }
  
  /* 라디오 버튼 그룹 스타일 */
  .radio-group {
    display: flex;
    justify-content: center;
  }
  
  .radio-group label {
    font-size: 16px;
    color: #ffffff;
  }
  
  /* 범위 입력창 스타일 */
  .input-range input {
    width: 48%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-color: rgba(28, 30, 38, 0.5);
    color: #ffffff;
    margin-right: 2%;
  }
  
  .input-range input:last-child {
    margin-right: 0;
  }

  .filter-reset{
    
    background-color: rgba(0,0,0,0);
    border: 1px solid rgba(255,255,255,0);
    border-radius: 0px;
    color: rgba(150,150,150,1);
    margin-bottom: 20px;
  }

  .filter-reset:hover{
    color:rgba(200,200,200);
  }

  @media (max-width: 768px) {
    .synopsis-input,
    .character-input,
    .input-container {
      width: 100%; /* 모바일에서 가로 폭을 전체로 설정 */
    }
    .input-all-container{
      width: 100%;
    }
    .filter-group{
     
    }
    .filter-wrapper{
      flex-direction: column;
    }

  }


  /* main */



  .main-space {
    display: flex;
    flex-direction: column; /* 세로로 정렬 */
    align-items: center;
    text-align: center; /* 텍스트 가운데 정렬 */
  }
  .main-black-g{
    width: 100%;
    height: 100%;
    /* background-image: linear-gradient(0deg, rgba(0, 0, 0,0), rgba(0, 0, 0, 1)); */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-title-am{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    width: 110px;
    height: 40px;
    color: #000000;
    margin-top: 90px; /* 90px */
  }
  .main-title{
    font-size: 32px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .main-white{
    width: 100%;
    height: 300px;
    background-color: rgba(0,0,0,0);
    margin-top: 100px;
  }

  .key-cursor{
    width: 12px;
    height: 30px;
    background-color: #ffffff;
    margin-left: 4px;
    animation: blink 1.3s step-end infinite;
  }
  @keyframes blink {
    0%, 100% {
      opacity: 1; /* 시작과 끝에서 불투명하게 보이게 설정 */
    }
    50% {
      opacity: 0; /* 중간 지점에서 투명하게 설정 */
    }
  }

  .main-content-text{
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: rgba(255,255,255,.8);
  }


  .main-register-button{
    margin-top: 30px;
    width: 300px;
    font-size: 24px;
    font-weight: 700;
    padding: 8px 16px;
    background-color: rgba(171, 100, 234, 0.3);
    color: #ffffff;
    border: 2px solid #AB64EA;
    border-radius: 8px;
    cursor: pointer;
    backdrop-filter: blur(6px);
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .main-register-button:hover{
    background-color: rgba(171, 100, 234, 0.6);
    border-color: #ffffff;
  }

  .main-actorlist-button{
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    background-color: rgba(0,0,0,0);
    border: 0px;
  }

  .main-actorlist-button:hover{
    opacity: 80%;
  }

  .main-img {
    width: 800px;
    height: auto;
    transition: transform 0.5s ease;
    box-sizing: border-box;
  }

  .slider-container {
    position: relative;
    width: 800px;
    overflow: hidden; /* 슬라이더 경계 밖으로 나가는 요소들을 숨김 */
    margin: auto;
    box-sizing: border-box;
    
  }
  
  .slider {
    display: flex;
    transition: transform 0.3s ease-in-out; /* 부드러운 이동 효과 */
  }
  
  .slide {
    min-width: 100%; /* 각 슬라이드가 컨테이너의 100%를 차지하도록 설정 */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 10;
  }

  .slider-group{
    display: flex;
    margin-top: 10px;
  }
  
  .slider-button.left {
    
  }
  
  .slider-button.right {
    
  }
  
  @media(max-width: 768px){
    .main-img,
    .slider-container{
      width: calc(100% - 20px);
    }
  }

  .footer{
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    color: #888888;
    font-size: 14px;

  }

  .footer-bar{
    width: calc(100% - 60px);
    background-color: rgba(255,255,255,.2);
    height: 1px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .cover-slider {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 300px;
  }
  
  .cover-slider-track {
    display: flex;
    height: 300px;
    animation: scroll 40s linear infinite;
  }
  
  .cover-image-container {
    position: relative;
    width: 200px;
    height: 300px;
    flex-shrink: 0;
  }
  
  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-200px * 10));
    }
  }
  

  .main-real-white-g{
    background-image: linear-gradient(0deg, rgba(255, 255, 255,1), rgba(255, 255, 255, 0));
    height: 200px;
    width: 100%;
    margin-top: -200px;
    z-index: 999;
    /* border-bottom: 10px solid #ffffff; */
  }

  .main-real-white-g-re{
    background-image: linear-gradient(0deg, rgba(255, 255, 255,0), rgba(255, 255, 255, 1));
    height: 300px;
    width: 100%;
    z-index: 999;
    /* border-bottom: 10px solid #ffffff; */
  }

  .main-real-purple{
    background-color: rgba(131, 46, 209, 1);
    width: 100%;
    height: 2000px;
    margin-top: -300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 300px;
  }

  .main-real-black{
    background-color: #222222;
    width: 100%;
    height: 4500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-real-color{
    background-color: rgb(160, 88, 232);
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  



  .main-real-white{
    background-color: #ffffff;
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-real-white-2{
    background-color: #ffffff;
    width: 100%;
    height: 5100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-actor-model{
    margin-top: 50px;
    background-color: rgba(183, 27, 191, 0.9);
    border-radius: 10px;
    height: 40px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    
  }


  .main-castingdirector{
    margin-top: 50px;
    background-color: rgba(191, 27, 43, 0.9);
    border-radius: 10px;
    height: 40px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    
  }

  .main-amt{
    margin-top: 20px;
    color: #000000;
    font-size: 50px;
  }
 
    .main-amt-m{
      display: none;
    }

    .main-amt-2{
      margin-top: 40px;
      color: rgba(160,88,232,1);
      font-size: 40px;
      text-shadow: 0px 3px 30px #dddddd;
    }
    
      .main-amt-m-2{
        display: none;
      }
  
      .main-amt-line{
        height: 100px;
        width: 1px;
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(160,88,232,1) 50%);
      }
 
      .main-cd-line{
        height: 100px;
        width: 1px;
        background-color: #ffffff;
      }
    
  .m-t-1{
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .m-t-2{
    color: #222222;
    font-size: 40px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 200px;
  }

.m-d-g{
  display: flex;
  margin-top: 100px;
}

.m-d-g-2{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 30px;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
}


.m-d-1{
  display: flex;
  gap: 10px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.m-d-2{
  font-size: 30px;
  font-weight: 700;
  margin-top: 10px;
}

.m-d-3{
  margin-top: 200px;
  font-size: 50px;
  font-weight: 700;
}

.m-d-4{
  background: linear-gradient(225deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-size: 36px;
  margin-top: 200px;
}

.m-d-5{
  font-size: 40px;
  font-weight: 700;
}

.m-d-6:hover{
  opacity: 80%;
}

  .filsu{
    font-size: 12px;
    color: rgba(255,0,0);
  }
  
  .m-am-1{
    color: #111111;
    font-size: 30px;
    font-weight: 900;
    margin-top: 50px;
    margin-bottom: 20px; 
  }
  .m-am-2{
    color: #666666;
    font-size: 28px;
    font-weight: 700;
  }
  .m-am-3{
    color: #222222;
    font-size: 35px;
    font-weight: 900;
    margin-top: 30px;
  }
  .m-am-4-img{
    width: 700px;
    filter: blur(2px);
    martin-top: 40px;
    margin-bottom: 40px;
  }

  .m-am-5-img{
    width: 600px;
    margin-top: 90px;
    border-radius: 10px;
    box-shadow: 0px 3px 30px 0px #dddddd;
  }
  .m-am-6-img{
    width: 550px;
    margin-top: 120px;
    border-radius: 10px;
    box-shadow: 0px 3px 30px 0px #dddddd;
  }
  .m-am-7{
    color: #111111;
    font-size: 28px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .m-am-8{
    color: #555555;
    font-size: 28px;
    font-weight: 800;
    margin-top: 150px;
  }
 
  .m-am-9-img{
    width: 90px;
    margin-right: 5px;
  }
  .m-am-10{
    color: #222222;
    font-size: 35px;
    font-weight: 800;
    margin-top: 0px;
  }
  .m-cd-3-img{
    width: 700px;
    border-radius: 15px;
    margin-top: 30px;
    box-shadow: 0px 3px 30px 0px #00000055;
  }

  .m-cd-4{
    margin-top: 30px;
    font-size: 40px;
    font-weight: 800;
  }
  .m-cd-5{
    margin-top: 30px;
    font-size: 24px;
    font-weight: 700;
  }
  .m-cd-6-img{
    width: 700px;
    border-radius: 15px;
    margin-top: 20px;
    box-shadow: 0px 3px 30px 0px #00000055;
  }
  .m-cd-7-img{
    width: 400px;
    margin-top: 20px;
  }

  .m-cd-8{
    margin-top: -10px;
    font-size: 30px;
    font-weight: 700;
  }
  .m-cd-9{
    width: 50px;
    height: 50px;
    background-color: #222222;
    margin-top: 92px;
  }
  .m-dp-1{
    width: 50px;
    height: 50px;
    background-color: rgba(131, 46, 209, 1);
  }
  .m-dp-2{
    font-size: 40px;
    margin-top: 30px;
  }
  .m-dp-3{
    font-size: 40px;
    font-weight: 900;
    margin-top: 300px;
  }
  .m-dp-4-img{
    width: 110px;
    margin-right: 5px;
  }
  .m-dp-5-img{
    height: 300px;
    margin-right: 5px;
    border-radius: 10px;
    box-shadodw: 0px 3px 30px 0px #00000022;
  }
  .m-dp-6-img{
    height: 400px;
    margin-top: 150px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 30px 0px #00000022;
  }
  .m-dp-7-img{
    width: 110px;
    margin-right: 5px;
  }



    @media(max-width: 768px){
      .main-amt-m{
        display: flex;
        margin-top: 20px;
        color: #000000;
        font-size: 40px;
      }
      .main-amt{
        font-size: 30px;
      }
      .main-amt-2{
        display: none;
      }
      .main-amt-m-2{
        display: flex;
        margin-top: 40px;
        color: rgba(160,88,232,1);
        font-size: 40px;
        text-shadow: 0px 3px 30px #dddddd;
      }
      .main-real-white{
        height:900px;
      }
      .m-k-1{
        font-size: 12px !important;
      }
      .m-k-1c{
        font-size: 12px !important;
      }
      .m-am-1{
        font-size: 22px;
      }
      .m-t-2{
        font-size: 25px;
      }
      .m-am-2{
        font-size: 24px;
      }
      .m-am-3{
        font-size: 30px;
        margin-top: 10px;
      }
      .m-am-4-img{
        width: 380px;
        margin-top: 50px;
      }
      .m-am-5-img{
        width: 330px;
      }
      .m-am-6-img{
        width: 350px;
      }
      .m-am-7{
        font-size: 20px;
      }
      .m-am-8{
        font-size: 22px;
      }
      .m-am-9-img{
        width: 75px;
      }
      .m-am-10{
        font-size: 26px;
      }
      .m-box-s-group{
        flex-direction: column;
      }
      .m-box-s{
        width: 300px !important;
        height: 300px !important;
      }
      .main-real-white-2{
        height: 5500px;
      }

      .m-cd{
        display: none;
      }
      .m-cd-m{
        display: flex !important;
        margin-top: 20px;
        font-size: 32px;
        margin-bottom: 50px;
      }
      .m-cd-1{
        font-size: 24px;
      }
      .m-cd-3-img{
        width: 350px;
        border-radius: 10px;
      }
      .m-cd-4{
        font-size: 28px;
      }
      .m-cd-5{
        font-size: 20px;
        margin-top: 10px;
      }
      .m-cd-6-img{
        width: 350px;
        border-radius: 10px;
      }
      .m-cd-7-img{
        width: 300px;
        margin-top:30px;
      }
      .m-cd-8{
        font-size: 20px;
      }
      .m-cd-9{
        width: 30px;
        height: 30px;
        margin-top: 60px;
      }
      .main-real-purple{
        height: 1500px;
      }
      .m-dp-1{
        display: block; 
        width: 30px;
        height: 30px;
        margin-top: 25px;
        box-sizing: content-box;
      }
      .main-staff-i{
        margin-top: 50px !important;
      }
      .m-d-g{
        flex-direction: column;
        margin-top: 0px;
      }
      .m-d-g-2{
        text-align: center;
        margin-left: 0px;
        font-size: 24px;
        margin-top: 20px;
      }
      .m-dp-2{
        margin-top: 100px;
      }
      .m-dp-3{
        font-size: 30px;
        margin-top: 130px;
      }
      .m-dp-4-img{
        width: 80px;
      }
      .m-d-1{
        flex-direction: column;
      }
      .m-dp-5-img{
        height: auto;
        width: 300px;
      }
      .m-d-2{
        font-size: 20px;
      }
      .m-dp-6-img{
        width: 350px;
        border-radius: 10px;
        height: auto;
      }
      .m-d-2{
        margin-top: 0px;
      }
      .m-d-3{
        font-size: 30px;
      }
      .m-d-4{
        font-size: 24px;
      }
      .m-d-5{
        font-size: 26px;
      }
      .m-dp-7-img{
        width: 70px;
      }

  }

    .main-amt-content{
      color: #555555;
      font-weight: 600;
      font-size: 24px;
      margin: 30px;
      margin-bottom: 40px;
    }
    .main-amt-content-m{
      display: none;
    }

.m-k-g{
  display: flex;
  gap: 10px;
}

.m-box-s-group{
  display: flex;
  gap: 30px;
  margin-top: 70px;
}

.m-box-s{
  width: 300px;
  height: 300px;
  box-shadow: 0px 3px 30px #dddddd;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}


.m-box-s3,
.m-box-s2,
.m-box-s1{
  display: flex;
  justify-content: center;
  color: #111111;
}
.m-box-s1{
  height: 100px;
  align-items: flex-end;
}
.m-box-s2{
  padding-top: 30px;
  height: 70px;
  font-size: 24px;
  font-weight: 700;
}
.m-box-s3{
  font-size: 20px;
  font-weight: 700;
  color: #555555;
}


.m-k-1{
  padding: 8px 10px;
  color: rgba(160,88,232,1);
  border: 2px solid rgba(160,88,232,1);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  opacity: 30%;
}

.m-k-1c{
  padding: 8px 10px;
  color: #ffffff;
  background-color: rgba(160,88,232,1);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0px 3px 8px 0px #dddddd;
}

.m-cd{
  margin-top: 40px;
  font-size: 40px;
  margin-bottom: 50px;
}
.m-cd-m{
  display: none;
}
.m-cd-1{
  margin-top: 50px;
  margin-bottom: 70px;
}
.m-cd-2{
  font-size: 30px;
  font-weight: 600;
}



  .main-staff-info{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-staff-i{
    margin-top: 100px;
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid #ffffff;
    border-radius: 10px;
    height: 40px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    
  }

.main-staff{
  margin-top: 20px;
  color: #ffffff;
  font-size: 50px;
}

  .main-staff-m{
    display: none;
  }

  
  
  .main-staff-line{
    height: 100px;
    width: 1px;
    background-color: #ffffff;
  }
  
  
   


    .main-form-group {
      margin-bottom: 10px;
      padding: 10px 20px 10px;
      border-radius: 8px;
      background-color: rgba(28, 30, 38, 0.9); /* 흐림 효과를 위한 투명도 추가 */
      margin-left: auto; /* 왼쪽 여백 자동 설정 */
      margin-right: auto; /* 오른쪽 여백 자동 설정 */
      width: 500px; /* 폼 그룹의 최대 너비 설정 */
      box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 추가 */
      border: 1px solid rgba(255, 255, 255, 0.1); /* 테두리 선 추가 */
      backdrop-filter: blur(8px); /* 배경 흐림 효과 추가 */
    }

    .main-form-label{
      display: block;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
      margin-bottom: 8px;
      padding-bottom: 0px;
      padding-top: 10px;
      text-align: left; /* 텍스트를 왼쪽 정렬 */
    }
  .label-number {
    color: #AB64EA; 
    margin-right: 4px; /* 숫자와 텍스트 사이의 간격 */
  }
  .main-keyword-lang,
  .main-keyword-satu,
  .main-keyword-talent,
  .main-keyword-sports,
  .main-keyword-style{
    color: #ffffff;
    margin-right: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    font-size: 14px;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    display: inline-block;
    margin: 2px;
  }

.main-keyword-style{
  background-color: rgba(242,147,37, .9);
}
.main-keyword-sports{
  background-color: rgba(2,82,89, .9);
}
.main-keyword-talent{
  background-color: rgba(0,113,114, .9);
}
.main-keyword-satu{
  background-color: rgba(150, 150, 150, .9);
}
.main-keyword-lang{
  background-color: rgba(217,79,4, .9);
  
}

.main-amt-img2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.main-url-i{
  margin-top: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #1e394c;
  color: #aaaaaa;
  width: 250px;
  height: 35px;
  justify-content: space-around;
}

.main-amt-img-rc{
  width: 480px;
  margin-left: 5px;
  margin-right: 5px;
}

.main-amt-img-rc2{
  width: 480px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px;
}
.main-link-content{
  color: black;
  font-size: 28px;
  font-weight: 500;
  margin-top: 20px;
}
.main-link-content-m{
  display: none;
}

@media(max-width: 768px){
    .main-staff-m{
      display: flex;
      margin-top: 20px;
      color: #ffffff;
      font-size: 30px;
    }
    .main-staff{
      display: none;
    }
    .main-form-group{
      width: calc(100% - 20px);
      margin-left: 20px;
      margin-right: 20px;
      box-sizing: border-box;

    }
    .main-form-label{
      font-size: 16px;
    }
    .main-amt-content{
      display: none;
    }
    .main-amt-content-m{
      display: inline;
      align-items: center;
      justify-content: center;
      color: #000000;
      font-weight: 500;
      font-size: 24px;
      margin: 30px;
    }
    .main-link-content{
      display: none;
    }

    .main-link-content-m{
      display: inline;
      color: black;
      font-size: 28px;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  @media(max-width: 1015px){
    .main-amt-img2{
      flex-direction: column;
    }
    .main-amt-img-rc2,
    .main-amt-img-rc{
      width: 350px;
      box-sizing: border-box;
    }
    .m-box-s-group{
      flex-direction: column;
    }
    .main-real-black{
      height: 5200px;
  }
  .adBanner{
    font-size: 14px;
  }
  }



  .adBanner{
    margin-top: 60px;
    width: 100%;
    height: 40px;
    background-color: #fcba03;
    display: flex;
    color: #000000;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    justify-content: space-between;
  }