/* src/components/Card.css */
.actor-card {
    width: 165px;
    border: 0px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: rgba(0,0,0, 0.5);
  }
 
  .actor-card:hover {
    transform: scale(1.05);
  }
  
  .actor-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-actor-info {
    padding: 4px 0px 10px 10px;
    text-align: left;
    font-size: 12px;
  }
  .card-actor-name{
    font-size: 16px;
    font-weight: 700;
  }
  /* 남성 배우 카드 스타일 */
.card-actor-male {
  background-color: rgba(40,69,108,1);
  border-radius: 2px;
  width: 30px;
  text-align: center;
  padding: 1px;
}

/* 여성 배우 카드 스타일 */
.card-actor-female {
  background-color: rgb(105, 49, 76);
  border-radius: 3px;
  width: 30px;
  text-align: center;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.gender-fill{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.card-actor-info{
  display: flex;
  flex-direction: column;
}

.card-brith-group,
.card-height-group,
.card-weight-group{
  display: flex;
}

.fill-text{
  width: 60px;
}

.card-gauge{
  display:flex;
  align-items: center;
  width: 80px;
}

.card-name-gender-group{
  display: flex;
  justify-content: space-between; /* 요소를 양쪽 끝으로 배치 */
  align-items: center;
  margin-right: 14px;
  margin-bottom: 4px;
}

.card-filmography{
  margin-top: 5px;
}