/* src/pages/ActorDetail.css */

.actor-topbar{
  margin-top: 60px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.actor-topbar-button{
  background-color: rgba(0,0,0,0);
  border: 1px;
  width: 30px;
  height: 30px;
}

.actor-topbar-button:hover{
  opacity: 0.7;
}

.actor-topbar-icon{
  width: 30px;
  height: 30px;
}

.actor-topbar-group1{
  display: flex;
  flex: 1;
}

.actor-topbar-group2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-right:5px;
}


.actordetail-all {
  position: relative;
  overflow: hidden;
}

.actordetail-all::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--cover);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(100px); /* 배경 흐림 효과 */
  z-index: -1;
}
.actordetail-all::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* 검정색, 40% 불투명도 */
  z-index: -1; /* 검정색 레이어를 배경 이미지 위로 보내기 */
}

.actor-detail {
  position: relative;
  z-index: 1;
  padding: 20px;
  border-radius: 10px;
  max-width: 1200px;
  margin: auto;
}

.style-filmography{
  margin-top: 20px;
}

.actor-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative; /* 상대적 위치 설정 */
  margin-bottom: 20px;
}

.actor-info {
  flex: 1;
  min-width: 300px;
  width: 48%;
  text-align: left; /* 텍스트 좌측 정렬 */
}

.actor-cover-image {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 300px;
  width: 48%;
  justify-content: flex-end;
  flex-direction: column;
}

.actor-image-detail {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  /* width: 450px; */
}

.keyword-badge {
  display: inline-block;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px;
  font-size: 14px;
  font-weight: 500;
  
}


.keyword-badge.style-keywords,
.keyword-badge.style-languages,
.keyword-badge.style-dialects,
.keyword-badge.style-talents,
.keyword-badge.style-sports,
.keyword-badge.style-drivinglicense {
  margin-right: 5px;
  margin-bottom: 10px;
}

.keyword-badge.style-keywords {
  background-color: rgba(242,147,37, .3); /* 스타일 키워드 배경색 */
  color: #ffffff;
}

.keyword-badge.style-languages {
  background-color: rgba(217,79,4, .3); /* 방언 배경색 */
  color: #ffffff;
  
}

.keyword-badge.style-dialects {
  background-color: rgba(244,226,222, .3); /* 언어 배경색 */
  color: #ffffff;
}

.keyword-badge.style-talents {
  background-color: rgba(0,113,114, .3); /* 재능 배경색 */
  color: #ffffff;
}

.keyword-badge.style-sports {
  background-color: rgba(2,82,89, .3); /* 스포츠 배경색 */
  color: #ffffff;
}

.keyword-badge.style-drivinglicense {
  background-color: rgba(0,0,0, .3); /* 운전면허 배경색 */
}

.self-introduction {
  white-space: pre-wrap; /* 줄바꿈을 적용하여 보여줌 */
  margin-top: 30px;
  margin-bottom: 30px;
}

.style-g1{
  display: flex;
  flex:1;
  
}


.style-g1-u2{
  margin-left: 10px;
  margin-top: 5px;
  
}

.style-name{
  font-size: 40px;
  font-weight: 700;
}

.style-englishname{
  font-size: 15px;
  font-weight: 200;
}

.style-role-actor{
  background-color: #F294D9;
  background-color: rgba(242, 148, 217, 0.5);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);;
  width: 40px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.style-role-model{
  background-color: #;
  background-color: rgba(74, 202, 217, 0.5);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);;
  width: 40px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-role-am{
  background-color: rgba(79, 27, 191, 0.5);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);;
  width: 70px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-gender-male{
  background-color: rgba(40,69,108,1);
  border-radius: 2px;
  width: 30px;
  text-align: center;
  padding: 1px;
  width: 40px;
  margin-top: 5px;
}
.style-gender-female{
  background-color: rgb(105, 49, 76);
  border-radius: 2px;
  width: 30px;
  text-align: center;
  padding: 1px; 
  width: 40px;
  margin-top: 5px;
}

.style-g2{
  display: flex;
  gap: 10px;
  color: #ffffff;
  margin-top: 10px;
  flex-direction: column;
}

.style-affiliation{
  margin-top: 10px;
  font-size: 14px;
}

.style-education{
  font-size: 14px;
}
.style-height-group,
.style-weight-group,
.style-shoesize-group,
.style-brith-group{
  display: flex;
  flex: 1;

}

.style-height,
.style-weight,
.style-shoesize,
.style-brithdate{
  display: flex;
  width: 100px;
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.gauge{
  display:flex;
  align-items: center;

}

.age-bar {
  width: 100px;
  background-color: rgba(255,255,255,0.3);
  border-radius: 5px;
  height: 5px;
}

.age-bar-fill-male {
  height: 100%;
  background-color: rgba(40,69,108,1); /* 그래프 색상 */
  border-radius: 5px;
  transition: width 0.3s ease;
}
.age-bar-fill-female {
  height: 100%;
  background-color: rgb(105, 49, 76); /* 그래프 색상 */
  border-radius: 5px;
  transition: width 0.3s ease;
}

.style-actor-info{
  background-color: rgba(0,0,0,0);
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  border-left: 2px solid #ffffff;
}


.image-collage {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}

.image-collage img {
  flex: 1 0 calc(33.333% - 10px); /* 3열 기본 레이아웃 */
  max-width: calc(33.333% - 10px);
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

/* 이미지가 3장 미만일 때 */
.image-collage.few-images {
  flex-direction: row;
  justify-content: flex-start;
}

.image-collage.few-images img {
  flex: 0 0 auto;
  width: 100%;

  height: 100%; /* 이미지 높이 고정 */
}

/* 반응형 스타일링 */
@media (max-width: 768px) {
  .image-collage img {
    flex: 1 0 calc(50% - 10px); /* 2열 레이아웃 */
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .image-collage img {
    flex: 1 0 100%; /* 1열 레이아웃 */
    max-width: 100%;
  }
}

/* 모달 스타일 */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* 70% 투명도 검정색 배경 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001; /* 모달이 가장 위에 나타나도록 설정 */
}

.image-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  margin-top: 30px;
  
}

.enlarged-image {
  max-width: 100%;
  max-height: 600px;
  border-radius: 0px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  padding: 5px 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.close-img{
  width: 20px;
  height: 20px;
}






.filmography-content{
  line-height: 180%;
  font-size: 16px;
  text-align: left;
}

.func-button{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
}
.ipAddress{
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  font-size: 12px;
  color: rgba(255,255,255,.4);
}

.profile-edit-button{
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 5px;
  height: 30px;
  padding: 0px 10px 0px 10px;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.profile-delete-button{
  background-color: rgba(0, 0, 0, 0.2);
  color: #ff3333;
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 5px;
  height: 30px;
  width: 80px;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.profile-edit-button:hover,
.profile-delete-button:hover{
  border: 1px solid #ffffff;
}
.call-field{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.call-input{
  width: 700px; /* 가로 폭 동일하게 설정 */
  height: 300px;
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  margin-bottom: 20px; /* 입력창 아래 간격을 더 넓게 설정하여 글자 수 표시와 간격 확보 */
  border: 1px solid rgba(255, 255, 255, 0.2); /* 흐림 효과를 위한 테두리 */
  border-radius: 10px;
  background-color: rgba(28, 30, 38, 0.5); /* 배경 흐림 효과를 위한 투명도 추가 */
  backdrop-filter: blur(8px); /* 배경 흐림 효과 추가 */
  color: #ffffff;
  position: relative; /* 글자 수 표시를 위한 상대적 위치 설정 */
  padding: 10px;
  box-sizing: border-box;
}
.call-affiliation-input{
  width: 700px; /* 가로 폭 동일하게 설정 */
  height: 50px;
  padding: 10px 0px 10px 0px;
  font-size: 16px;
  margin-bottom: 10px; /* 입력창 아래 간격을 더 넓게 설정하여 글자 수 표시와 간격 확보 */
  border: 1px solid rgba(255, 255, 255, 0.2); /* 흐림 효과를 위한 테두리 */
  border-radius: 10px;
  background-color: rgba(28, 30, 38, 0.5); /* 배경 흐림 효과를 위한 투명도 추가 */
  backdrop-filter: blur(8px); /* 배경 흐림 효과 추가 */
  color: #ffffff;
  position: relative; /* 글자 수 표시를 위한 상대적 위치 설정 */
  padding: 10px;
  box-sizing: border-box;
}

.casting-button{
  font-size: 24px;
  background-color: rgba(0,0,0,0);
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 100px;
  width: 200px;
  height: 60px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.casting-button:hover{
  background-color: rgba(0,0,0,0.3);
}

.cast-info{
  color: #bbbbbb;
  margin-bottom: 300px;
  font-size: 14px;
  line-height: 200%;
}
.youtube-size{
  margin: 5px;
  box-sizing: border-box;
}

.fee-design{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  bottom: 0; /* 컨테이너의 하단에 고정 */
  left: 0; /* 컨테이너의 왼쪽에 위치 */
}

.fee-freework,
.fee-movie,
.fee-ad{
  width: 200px;
}

/* 아이콘 이미지 스타일 */
.freeWork-info-img {
  width: 12px;
  height: 12px;
  opacity: 60%;
  margin-left: 5px;
}

/* 툴팁 스타일 */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  font-size: 12px;
  color: rgba(200,200,200);
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -300%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* 마우스를 올렸을 때 툴팁 표시 */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.social-links {
  margin-top: 10px;
  display: flex;
  gap: 15px; /* 아이콘 간격을 조절 */
  
}

.social-icon {
  width: 40px; /* 아이콘 크기 */
  height: 40px;
  cursor: pointer; /* 커서를 포인터로 변경 */
  transition: transform 0.3s;
  border-radius: 1000px;
}

.social-icon:hover {
  transform: scale(1.1); /* 마우스를 올렸을 때 아이콘 확대 효과 */
}


.empty-title{
  text-align: left;
  font-size: 18px;
  color: rgba(180,180,180);
}


/* 반응형 스타일링 */
@media (max-width: 768px) {
  .actor-container {
    flex-direction: column;
    align-items: center;
  }

  .actor-info,
  .actor-cover-image {
    width: 100%;
    text-align: center;
    margin: 10px 0;
    justify-content: center;
  }
  .youtube-size{
    width: 100%;
    height: 250px;
  }
.call-input,
  .call-affiliation-input{
    width: 100%;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    font-size: 12px;
    color: rgba(200,200,200);
    text-align: left;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: -400%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.like-number{
  font-size: 14px;
  font-weight: 700;
}

.view-number{
  font-size: 14px;
  font-weight: 700;
}

.like-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 10002;
}

.like-tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 999;
  bottom: -30px; /* 툴팁이 버튼 위에 나타나도록 설정 */
  left: 50%;
  margin-left: -85px;
  opacity: 0;
  transition: opacity 0.3s;
}

.like-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
